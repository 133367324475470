import { Suspense } from 'react';
import { useDispatch } from 'react-redux';
// import HealthCheck from 'healthCheck';
import { updateUserStatus } from 'redux/actions';
import { USER_STATES } from 'constants/userState';
import NexustiDialerProvider, {
  WEBPHONE_PROVIDER,
  WEBPHONE_SDK,
} from 'components/dialer/nexusti/nexusti-dialer-provider';
import { getLoggedInUser } from 'helpers/authUtils';
import NxRouterContainer from 'router';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { merge } from 'lodash-es';
import { themeColors } from 'layouts/theme/themeColors';
import themeOptions, { IThemeOptions } from 'layouts/theme/themeOptions';
import { ColorModeContext } from 'ColorMode';
import { I18nextProvider } from 'react-i18next';
import ReloadPrompt from 'ReloadPrompt';
import i18n from './i18n';

const Notification = lazy(() => import('Notification'));

/**
 * Main app component
 */

function App() {
  // const isProduction = import.meta.env.NODE_ENV === 'production';

  const user = getLoggedInUser();

  const dispatch = useDispatch();

  const handleChangeStatus = (status: string) => {
    dispatch(updateUserStatus(status));
  };

  const onCalling = () => {
    handleChangeStatus(USER_STATES.inCall);
  };

  const onAvailable = () => {
    handleChangeStatus(USER_STATES.available);
  };

  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      mode,
    }),
    [mode]
  );

  const theme = useMemo(() => createTheme(merge({}, themeColors[mode], themeOptions) as IThemeOptions), [mode]);

  // Prevent zooming input on iOS
  theme.typography.body1 = {
    ...theme.typography.body1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  };

  return (
    <I18nextProvider i18n={i18n}>
      <StyledEngineProvider injectFirst>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <Toaster />
            <CssBaseline />
            <Suspense fallback={null}>
              <Notification />
            </Suspense>
            <NexustiDialerProvider
              webPhoneList={[
                {
                  type: WEBPHONE_SDK.PBX,
                  provider: WEBPHONE_PROVIDER.CGV,
                  username: user?.callUsername,
                  password: user?.callPassword,
                  domain: 'cloud.telebot.vn',
                },
              ]}
              onCalling={onCalling}
              onAvailable={onAvailable}
            >
              <BrowserRouter>
                <NxRouterContainer />
              </BrowserRouter>
            </NexustiDialerProvider>
            {/* <HealthCheck /> */}
            <ReloadPrompt />
          </ThemeProvider>
        </ColorModeContext.Provider>
      </StyledEngineProvider>
    </I18nextProvider>
  );
}

export default App;
