import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { ListWithPagination } from 'redux/types';
import { prepareAuthHeaders } from 'helpers/api';
import { toFormData } from 'helpers/form';
import { AssignLead, AssignLeadByFilter, AssignLeadByIds, LeadDetail, LeadTaskInfo } from './types';

export const leadApi = createApi({
  reducerPath: 'leadApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.LEAD_API,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: [
    'leadList',
    'leadTasks',
    'leadDetail',
    'leadAlerts',
    'leadNotes',
    'leadEvents',
    'leadTasks',
    'leadTaskLogs',
    'leadActivities',
    'leadAioActivities',
    'leadAdditionalDocumentRequest',
    // 'leadDocuments',
    'leadCic',
    'leadCurrentAssignCandidate',
    'leadNextAssignCandidate',
    'leadDemoData',
  ],
  endpoints: (builder) => ({
    filterLeads: builder.query({
      query: ({ filter, pagination }) => ({
        url: `lead/filter`,
        method: 'POST',
        params: pagination,
        body: filter,
      }),
      transformResponse: (response: ListWithPagination<LeadDetail>) => response.list,
      providesTags: ['leadList'],
    }),
    fetchLeadDetail: builder.query<LeadDetail, string>({
      query: (id) => `lead/${id}`,
      providesTags: ['leadDetail'],
    }),
    fetchLeadAlerts: builder.query<any, string>({
      query: (id) => `alert/${id}`,
      providesTags: ['leadAlerts'],
    }),

    fetchLeadCurrentAssignCandidate: builder.query<any, string>({
      query: (id) => `lead/${id}/assign/candidate/current`,
      providesTags: ['leadCurrentAssignCandidate'],
    }),
    fetchLeadNextAssignCandidate: builder.query<any, any>({
      query: ({ id, filter }) => ({
        url: `lead/${id}/assign/candidate/next`,
        method: 'POST',
        body: filter,
      }),
      providesTags: ['leadNextAssignCandidate'],
    }),
    fetchLeadTasks: builder.query<LeadTaskInfo[], any>({
      query: (options) => ({
        url: '/setting/flow/lead/task',
        method: 'GET',
        params: options,
      }),
      providesTags: ['leadTasks'],
    }),
    assignLeadByIds: builder.mutation<AssignLead, AssignLeadByIds>({
      query: ({ leadIds, assignee, owner, groupId }) => ({
        url: '/lead/assign',
        method: 'POST',
        body: { leadIds, assignee, owner, groupId },
      }),
      invalidatesTags: ['leadList'],
    }),
    assignLeadByFilter: builder.mutation<AssignLead, AssignLeadByFilter>({
      query: ({ leadFilter, assignee, owner, groupId }) => ({
        url: '/lead/assign/filter',
        method: 'POST',
        body: { leadFilter, assignee, owner, groupId },
      }),
      invalidatesTags: ['leadList'],
    }),
    // fetchLeadDocuments: builder.query({
    //   query: ({ id, pagination }) => ({
    //     url: `document/${id}`,
    //     params: pagination,
    //   }),
    //   providesTags: ['leadDocuments'],
    // }),

    createLead: builder.mutation({
      query: (data) => ({
        url: `lead/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadList'],
    }),
    updateLead: builder.mutation({
      query: (data) => ({
        url: `lead/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['leadDetail', 'leadAlerts'],
    }),
    completeCurrentStepLead: builder.mutation({
      query: ({ id, data }) => ({
        url: `lead/${id}/complete`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [
        'leadDetail',
        'leadAlerts',
        'leadTaskLogs',
        'leadAioActivities',
        'leadCurrentAssignCandidate',
        'leadNextAssignCandidate',
      ],
    }),

    updateLeadDemand: builder.mutation({
      query: ({ id, demand }) => ({
        url: `lead/demand/${id}`,
        method: 'PUT',
        body: demand,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const { data: updatedDemand } = await queryFulfilled;
        dispatch(
          leadApi.util.updateQueryData('fetchLeadDetail', id, (draft) => {
            draft.demand = updatedDemand;
          })
        );
      },
    }),

    /* CIC/S37 */

    fetchLeadS37: builder.query({
      query: (id) => `lead/s37/${id}`,
    }),
    updateLeadS37: builder.mutation({
      query: ({ id, data }) => ({
        url: `lead/s37/${id}`,
        method: 'PUT',
        body: toFormData(data),
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const { data: updatedS37 } = await queryFulfilled;
        dispatch(
          leadApi.util.updateQueryData('fetchLeadDetail', id, (draft) => {
            draft.s37 = updatedS37;
          })
        );
      },
    }),
    requestLeadS37: builder.mutation({
      query: ({ id, data }) => ({
        url: `cic-request/s37/${id}`,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const { data: updatedS37 } = await queryFulfilled;
        dispatch(
          leadApi.util.updateQueryData('fetchLeadDetail', id, (draft) => {
            draft.s37 = updatedS37;
          })
        );
      },
    }),

    // TODO: combine leadCic and lead.cic
    fetchLeadCic: builder.query({
      query: (id) => `lead/cic/${id}`,
      providesTags: ['leadCic'],
    }),
    updateLeadCic: builder.mutation({
      query: ({ id, data }) => ({
        url: `lead/cic/${id}`,
        method: 'PUT',
        body: toFormData(data),
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const { data: updatedCic } = await queryFulfilled;
        dispatch(
          leadApi.util.updateQueryData('fetchLeadDetail', id, (draft) => {
            draft.cic = updatedCic;
          })
        );
        dispatch(
          leadApi.util.updateQueryData('fetchLeadCic', id, (draft) => {
            Object.assign(draft, updatedCic);
          })
        );
      },
    }),
    requestLeadCic: builder.mutation({
      query: ({ id, data }) => ({
        url: `cic-request/cic/${id}`,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const { data: updatedCic } = await queryFulfilled;
        dispatch(
          leadApi.util.updateQueryData('fetchLeadDetail', id, (draft) => {
            draft.cic = updatedCic;
          })
        );
        dispatch(
          leadApi.util.updateQueryData('fetchLeadCic', id, (draft) => {
            Object.assign(draft, updatedCic);
          })
        );
      },
    }),
    reloadRequestLeadCic: builder.mutation({
      query: ({ id, requestId }) => ({
        url: `cic-request/cic/reload/${requestId}`,
        method: 'POST',
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const { data: updatedCic } = await queryFulfilled;
        dispatch(
          leadApi.util.updateQueryData('fetchLeadDetail', id, (draft) => {
            draft.cic = updatedCic;
          })
        );
        dispatch(
          leadApi.util.updateQueryData('fetchLeadCic', id, (draft) => {
            Object.assign(draft, updatedCic);
          })
        );
      },
    }),

    /* Assign */

    assignLead: builder.mutation({
      query: ({ id, assignee }) => ({
        url: `lead/${id}/assign/${assignee}`,
        method: 'PUT',
      }),
      async onQueryStarted({ id, assignee }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          leadApi.util.updateQueryData('fetchLeadDetail', id, (draft) => {
            draft.assignee = assignee;
          })
        );
      },
    }),

    /* Import */

    importLead: builder.mutation({
      query: ({ data, checkOnly }) => ({
        url: `lead/import/user${checkOnly ? '/check' : ''}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadList'],
    }),

    importEndedLead: builder.mutation({
      query: ({ data, checkOnly }) => ({
        url: `lead/import/ended${checkOnly ? '/check' : ''}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadList'],
    }),

    importLeadOnCampaign: builder.mutation({
      query: ({ data, checkOnly }) => ({
        url: `lead/import${checkOnly ? '/check' : ''}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadList'],
    }),

    /* Activity */

    fetchLeadNotes: builder.query({
      query: ({ id, params }) => ({
        url: `note/${id}/get-all/paging`,
        params,
      }),
      providesTags: ['leadNotes'],
    }),
    fetchLeadEvents: builder.query({
      query: ({ id, params }) => ({
        url: `event/${id}`,
        params,
      }),
      providesTags: ['leadEvents'],
    }),
    fetchLeadTaskLogs: builder.query({
      query: ({ id, params }) => ({
        url: `lead/${id}/flow/logs`,
        params,
      }),
      providesTags: ['leadTaskLogs'],
    }),
    fetchLeadActivities: builder.query({
      query: ({ id, params }) => ({
        url: `activity/${id}`,
        params,
      }),
      providesTags: ['leadActivities'],
    }),
    fetchLeadAioActivities: builder.query({
      query: ({ id, params }) => ({
        url: `activity/aio/${id}`,
        params,
      }),
      providesTags: ['leadAioActivities'],
    }),

    createLeadNote: builder.mutation({
      query: (data) => ({
        url: `note/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadNotes', 'leadAioActivities'],
    }),
    createLeadEvent: builder.mutation({
      query: (data) => ({
        url: `event/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadEvents', 'leadAioActivities'],
    }),
    createLeadActivity: builder.mutation({
      query: (data) => ({
        url: `activity/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadActivities', 'leadAioActivities'],
    }),

    /* Additional Document Request */
    fetchLeadAdditionalDocumentRequest: builder.query({
      query: (id) => `additional-request/${id}/result`,
      providesTags: ['leadAdditionalDocumentRequest'],
    }),
    updateLeadAdditionalDocumentRequest: builder.mutation({
      query: ({ id, data }) => ({
        url: `additional-request/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['leadAdditionalDocumentRequest'],
    }),

    /* Demo only data */
    fetchLeadDemoData: builder.query({
      query: (id) => `demo/${id}`,
      providesTags: ['leadDemoData'],
    }),
    updateLeadDemoData: builder.mutation({
      query: ({ id, data }) => ({
        url: `demo/${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadDemoData'],
    }),
  }),
});

export const {
  util: leadApiUtil,

  useFilterLeadsQuery,
  useLazyFilterLeadsQuery,
  useFetchLeadDetailQuery,
  useFetchLeadAlertsQuery,
  useFetchLeadTasksQuery,

  useFetchLeadCurrentAssignCandidateQuery,
  useFetchLeadNextAssignCandidateQuery,

  // useFetchLeadDocumentsQuery,

  useCreateLeadMutation,
  useUpdateLeadMutation,
  useCompleteCurrentStepLeadMutation,

  useUpdateLeadDemandMutation,

  useFetchLeadS37Query,
  useUpdateLeadS37Mutation,
  useRequestLeadS37Mutation,
  useFetchLeadCicQuery,
  useUpdateLeadCicMutation,
  useRequestLeadCicMutation,
  useReloadRequestLeadCicMutation,

  useAssignLeadMutation,

  useImportLeadMutation,
  useImportEndedLeadMutation,
  useImportLeadOnCampaignMutation,

  useAssignLeadByIdsMutation,
  useAssignLeadByFilterMutation,

  useFetchLeadNotesQuery,
  useFetchLeadEventsQuery,
  useFetchLeadTaskLogsQuery,
  useFetchLeadActivitiesQuery,
  useFetchLeadAioActivitiesQuery,

  useCreateLeadNoteMutation,
  useCreateLeadEventMutation,
  useCreateLeadActivityMutation,

  useFetchLeadAdditionalDocumentRequestQuery,
  useUpdateLeadAdditionalDocumentRequestMutation,

  useFetchLeadDemoDataQuery,
  useUpdateLeadDemoDataMutation,
} = leadApi;
