import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { prepareAuthHeaders } from 'helpers/api';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.SYS_ACC_API,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['groupList', 'userByGroupList'],
  endpoints: (builder) => ({
    fetchGroup: builder.query({
      query: () => ({
        url: '/account/group',
      }),
      providesTags: ['groupList'],
    }),

    fetchUserByGroup: builder.query({
      query: (groupId) => ({
        url: `/admin/account/group/${groupId}`,
      }),
      providesTags: ['userByGroupList'],
    }),
  }),
});

export const { useFetchGroupQuery, useFetchUserByGroupQuery } = adminApi;
