import './init';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';

// import reportWebVitals from './reportWebVitals';
// import { registerFcm } from './firebase/fcmRegistration';

import 'assets/scss/app.scss';

import './index.css';

// const isProduction = import.meta.env.NODE_ENV === 'production';

// if (isProduction) {
//   // eslint-disable-next-line no-console
//   console.log = () => {};
//   console.warn = () => {};
//   console.error = () => {};
// }

// const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
// const currentLanguage = getLanguage();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

// Background FCM notifications
// registerFcm();
