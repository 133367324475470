import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { prepareAuthHeaders } from 'helpers/api';

export const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.COMMON_API,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['regions', 'provinces', 'districts', 'wards'],
  endpoints: (builder) => ({
    fetchRegions: builder.query({
      query: () => `/address/region`,
      providesTags: ['regions'],
    }),
    fetchProvinces: builder.query({
      query: () => `/address/province`,
      providesTags: ['provinces'],
    }),
    fetchProvincesByRegion: builder.query({
      query: (id) => `/address/region/${id}/province`,
      providesTags: ['provinces'],
    }),
    fetchDistrictsByProvince: builder.query({
      query: (id) => `/address/province/${id}/district`,
      providesTags: ['districts'],
    }),
    fetchWardsByDistrict: builder.query({
      query: (id) => `/address/district/${id}/ward`,
      providesTags: ['wards'],
    }),

    createRegion: builder.mutation({
      query: (data) => ({
        url: `address/region/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['regions'],
    }),
    createProvince: builder.mutation({
      query: (data) => ({
        url: `address/province/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['provinces'],
    }),
    createDistrict: builder.mutation({
      query: (data) => ({
        url: `address/district/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['districts'],
    }),
    createWard: builder.mutation({
      query: (data) => ({
        url: `address/ward/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['wards'],
    }),

    deleteRegion: builder.mutation({
      query: (id) => ({
        url: `address/region/${id}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['regions'],
    }),
    deleteProvince: builder.mutation({
      query: (id) => ({
        url: `address/province/${id}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['provinces'],
    }),
    deleteDistrict: builder.mutation({
      query: (id) => ({
        url: `address/district/${id}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['districts'],
    }),
    deleteWard: builder.mutation({
      query: (id) => ({
        url: `address/ward/${id}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['wards'],
    }),
  }),
});

export const {
  useFetchRegionsQuery,
  useFetchProvincesQuery,
  useFetchProvincesByRegionQuery,
  useFetchDistrictsByProvinceQuery,
  useFetchWardsByDistrictQuery,

  useCreateRegionMutation,
  useCreateProvinceMutation,
  useCreateDistrictMutation,
  useCreateWardMutation,

  useDeleteRegionMutation,
  useDeleteProvinceMutation,
  useDeleteDistrictMutation,
  useDeleteWardMutation,
} = commonApi;
