import { fetchJSON } from 'helpers/api';
import { ENP } from 'constants/api';

/* User API */
export const fetchUsers = (params) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/get-all`, options, params);
};

export const fetchUsersIncludeDeleted = (params) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/get-all/include-deleted`, options, params);
};

export const fetchUsersByFilter = (filter, pagination) => {
  const options = {
    body: JSON.stringify({ ...filter }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/get-all/filter`, options, pagination);
};

export const fetchUserDetail = ({ payload }) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/${payload}`, options);
};

export const fetchUsersByRole = (payload) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/role/${payload}`, options);
};
// TT
export const fetchAllTypeFunnel = (payload) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.LEAD_API}/conversion-funnel/get-all`, options);
};

export const fetchManagedMembers = () => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/account/managed-member`, options);
};

export const fetchManagedMembersIncludeDeleted = () => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/account/managed-member/include-deleted`, options);
};

export const createUser = (payload) => {
  const options = {
    body: JSON.stringify({ ...payload }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/create`, options);
};

export const createUserAvatar = (id, data) => {
  const formData = new FormData();
  formData.append('avatarFile', data);

  const options = {
    body: formData,
    method: 'PUT',
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/${id}/update-avatar`, options);
};

export const updateUser = (id, data) => {
  const options = {
    body: JSON.stringify({ ...data }),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/${id}`, options);
};

export const changePassword = (id, pwd) => {
  const options = {
    body: JSON.stringify({ id, password: pwd }),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/${id}/change-password`, options);
};

export const activeUser = (payload) => {
  const options = {
    method: 'PUT',
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/active/${payload}`, options);
};

export const deactiveUser = (payload) => {
  const options = {
    method: 'PUT',
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/deactivate/${payload}`, options);
};

export const deleteUser = (payload) => {
  const options = {
    method: 'DELETE',
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/${payload}`, options);
};

export const setLeaderUser = (payload) => {
  const options = {
    method: 'PUT',
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/${payload}/make-leader-group`, options);
};

export const updateUserPermissions = (id, data) => {
  const options = {
    body: JSON.stringify({ ...data }),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/${id}/role`, options);
};

/* Role API */

export const fetchRoles = () => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/role/level`, options);
};

export const createRole = (payload) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/role`, options);
};

export const updateRolePermission = (data) => {
  const options = {
    body: JSON.stringify({ ...data }),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/role/permission`, options);
};

export const updateRole = (id, data) => {
  const options = {
    body: JSON.stringify({ ...data }),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/role/${id}`, options);
};

export const deleteRole = (payload) => {
  const options = {
    method: 'DELETE',
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/role/${payload}`, options);
};

export const updateRolesPermission = (data) => {
  const options = {
    body: JSON.stringify(data),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/role/level`, options);
};

/* Group API */
export const fetchGroupTypes = () => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/group-type/get-all`, options);
};

export const fetchGroupsByType = (type) => {
  // TODO: update api
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/group/type/${type}`, options);
};

export const fetchGroups = () => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/group/level`, options);
};

export const fetchGroupTree = () => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/account/group`, options);
};

export const createGroup = (payload) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/group/create`, options);
};

export const updateGroup = (id, data) => {
  const options = {
    body: JSON.stringify({ ...data }),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/group/${id}`, options);
};

export const deleteGroup = (payload) => {
  const options = {
    method: 'DELETE',
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/group/${payload}`, options);
};

export const fetchGroupsWithUsers = ({ payload }) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/group/level/user`, options);
};

/* Resource API */
export const fetchClientRoles = () => {
  const options = {
    headers: {},
  };

  // todo: change to other api
  return fetchJSON(`${ENP.SYS_ACC_API}/admin/role/permission/get-all`, options);
};

/* Users by group API */
export const fetchUsersByGroup = (groupId) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/account/group/${groupId}`, options);
};

/* Param table API */
export const fetchParamTables = () => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.MATRIX_API}/v1/param-tables/`, options);
};

export const fetchParamTableDetail = (payload) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.MATRIX_API}/v1/param-tables/${payload}`, options);
};

export const updateParamTable = (payload) => {
  const options = {
    body: JSON.stringify({ ...payload.data }),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.MATRIX_API}/v1/param-tables/${payload.table}`, options);
};

export const deleteParamTable = (payload) => {
  const options = {
    method: 'DELETE',
    headers: {},
  };

  return fetchJSON(`${ENP.MATRIX_API}/v1/param-tables/${payload}`, options);
};

export const createParamTable = (payload) => {
  const options = {
    body: JSON.stringify({ ...payload }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.MATRIX_API}/v1/param-tables/create`, options);
};

export const fetchParamTableEntries = (payload) => {
  const options = {
    headers: {},
  };

  return fetchJSON(`${ENP.MATRIX_API}/v1/param-tables/${payload}/entries`, options);
};

export const createParamTableEntries = (tableId, data) => {
  const options = {
    body: JSON.stringify([...data]),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.MATRIX_API}/v1/param-tables/${tableId}/entries`, options);
};

export const updateParamTableEntries = (tableId, entryId, data) => {
  const options = {
    body: JSON.stringify({ ...data }),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.MATRIX_API}/v1/param-tables/${tableId}/entries/${entryId}`, options);
};

export const deleteParamTableEntries = (tableId, entryId) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.MATRIX_API}/v1/param-tables/${tableId}/entries/${entryId}`, options);
};

/* Notification */
export const fetchNotificationTemplate = (params) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.NOTIFICATION_API}/template/get-all`, options, params);
};

export const createTemplate = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.NOTIFICATION_API}/template/create`, options);
};

export const updateTemplate = (id, data) => {
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.NOTIFICATION_API}/template/${id}`, options);
};

export const deleteNotificationTemplate = (data) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.NOTIFICATION_API}/template/delete/${data}`, options);
};

/* Activity Log */
export const fetchActivityTypes = () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LOG_API}/v1/types`, options);
};

export const fetchActivityLogs = (params) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LOG_API}/v1/get-all`, options, params);
};

export const fetchActivityLogsByFilter = (data, params) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LOG_API}/v1/filter`, options, params);
};

/* Table flow */
export const fetchCurrentFlow = (objectType) => {
  const options = {
    method: 'GET',
    headers: {},
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/flow/${objectType}/current`, options);
};

export const createFlow = (objectType, data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/flow/${objectType}/create`, options);
};

/* Workflow */
export const fetchWorkflows = (pagination) => {
  const options = {
    method: 'GET',
    headers: {},
  };

  return fetchJSON(`${ENP.WORKFLOW_API}/v1/process-definition/get-all`, options, pagination);
};

export const fetchWorkflow = (id) => {
  const options = {
    method: 'GET',
    headers: {},
  };

  return fetchJSON(`${ENP.WORKFLOW_API}/v1/process-definition/${id}`, options);
};

export const deployWorkflow = (data) => {
  const formData = new FormData();
  formData.append('bpmnFile', data);

  const options = {
    method: 'POST',
    body: formData,
    headers: {},
  };

  return fetchJSON(`${ENP.WORKFLOW_API}/v1/process-definition/create`, options);
};

export const activateWorkflow = (id) => {
  const options = {
    method: 'PUT',
    headers: {},
  };

  return fetchJSON(`${ENP.WORKFLOW_API}/v1/process-definition/activate/${id}`, options);
};

export const deactivateWorkflow = (id) => {
  const options = {
    method: 'PUT',
    headers: {},
  };

  return fetchJSON(`${ENP.WORKFLOW_API}/v1/process-definition/deactivate/${id}`, options);
};

export const fetchDayOffEvent = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.CALENDAR_API}/v1/admin/setting/day-off/filter`, options);
};

export const createDayOffEvent = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.CALENDAR_API}/v1/admin/setting/day-off/create`, options);
};

export const deleteDayOffEvent = (id) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.CALENDAR_API}/v1/admin/setting/day-off/${id}`, options);
};

export const fetchCalendarSetting = (key) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.CALENDAR_API}/v1/admin/setting/${key}`, options);
};

export const setCalendarSetting = (key, value) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(value),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.CALENDAR_API}/v1/admin/setting/${key}`, options);
};

export const fetchAllKpiSetting = (data) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.COLLECTION_API}/kpi/get`, options);
};

export const setKpiSetting = (data) => {
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.COLLECTION_API}/kpi/set`, options);
};

export const fetchBucketSetting = (data) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.COLLECTION_API}/setting/bucket/get`, options);
};

export const setBucketSetting = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.COLLECTION_API}/setting/bucket/set`, options);
};

export const fetchActivityCodeSetting = () => {
  const options = {
    method: 'GET',
    headers: {},
  };

  return fetchJSON(`${ENP.COLLECTION_API}/setting/activity-code/get`, options);
};

export const setActivityCodeSetting = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.COLLECTION_API}/setting/activity-code/set`, options);
};

export const fetchActivityCodeLeadSetting = (data) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/activity-code/get`, options);
};

export const setActivityCodeLeadSetting = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/activity-code/set`, options);
};

export const fetchRelationshipSetting = (data) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.COLLECTION_API}/setting/relationship/get`, options);
};

export const setRelationshipSetting = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.COLLECTION_API}/setting/relationship/set`, options);
};

export const fetchCheckInLocationSetting = (data) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.COLLECTION_API}/setting/checkin-location/get`, options);
};

export const setCheckInLocationSetting = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.COLLECTION_API}/setting/checkin-location/set`, options);
};

export const fetchOrgInfo = (data) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/org/info`, options);
};

export const updateOrgInfo = (payload) => {
  const options = {
    body: JSON.stringify({ ...payload }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/org/info`, options);
};

export const updateOrgAvater = (avatar) => {
  const formData = new FormData();
  formData.append('file', avatar);
  const options = {
    body: formData,
    method: 'POST',
    headers: {},
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/org/avatar`, options);
};

export const testApi = (payload) => {
  const options = {
    body: JSON.stringify({ ...payload }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/group-type/create`, options);
};

export const fetchOrgAvatar = (data) => {
  return fetch(`${ENP.SYS_ACC_API}/admin/org/avatar`, {
    method: 'get',
    headers: new Headers({}),
  })
    .then((response) => response.blob())
    .then((myBlob) => {
      const objectURL = URL.createObjectURL(myBlob);
      throw new Error(objectURL);
    });
};

export const fetchAllGroupLocal = (id) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/group/local/${id}`, options);
};

export const updateLocation = ({ id, payload }) => {
  const options = {
    body: JSON.stringify(payload),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/group/local/${id}`, options);
};

export const updateUserStatus = (status) => {
  const options = {
    body: JSON.stringify({}),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/user/state/${status}`, options);
};

export const getUserStatus = () => {
  const options = {
    body: JSON.stringify({}),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/user/health-check`, options);
};
